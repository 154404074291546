.recruit {
    flex-wrap: wrap;
    align-items: center;
    padding: 150px 0 60px;
    background-color: #f0f0f0;

    .container {
        display: flex;

        @media screen and (max-width: 1023px) {
            flex-direction: column;
        }
    }

    &-title {
        &-wrap {
            width: 50%;

            @media screen and (max-width: 1023px) {
                width: 100%;
                margin-bottom: 100px;
            }
    
            @media screen and (max-width: 767px) {
                margin-bottom: 40px;
            }
        }

        &__heading {
            font-family: helveticaltstd-bold, sans-serif;
            color: #000;
            font-size: 60px;
            font-weight: 700;
            line-height: 1;
        }

        &__subtitle {
            margin: 10px 0 20px 0;
            color: #3c3c3c;
            font-family: "HiraKakuStdN-W8-AlphaNum", sans-serif;
            font-size: 18px;
            font-style: normal;
            letter-spacing: 5.76px;
        }
    }

    &-news {
        &-wrap {
            width: 50%;

            @media screen and (max-width: 1023px) {
                width: 100%;
            }
        }

        &__list {
            padding: 0;
            margin: 0;
            list-style: none;
        }

        &__item {
            display: flex;
            padding: 18px 0;
            border-bottom: 1px solid #231815;
            border-top: 1px solid #231815;
        }

        &__body {
            flex-grow: 1;
        }

        &__date {
            margin-bottom: 4px;
            font-family: "HiraKakuStdN-W8-AlphaNum", sans-serif;
            color: #6e6e6e;
            font-size: 13px;
            font-style: normal;
            letter-spacing: 1.82px;
        }

        &__heading {
            margin-bottom: 16px;
            font-family: "HiraKakuPro-W6-AlphaNum", sans-serif;
            color: #000000;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            letter-spacing: 2.24px;
            line-height: normal;

            & a:hover {
                color: $black;
            }
        }

        &__description {
            color: #6e6e6e;
            font-family: "HiraKakuStdN-W8-AlphaNum", sans-serif;
            font-size: 12px;
            font-style: normal;
            letter-spacing: 1.68px;
            line-height: normal;
            max-width: 350px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        &-link {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            margin-left: 20px;

            &__button {
                display: inline-block;
                color: #000;
                border: 1px solid #000;
                border-radius: 20px;
                padding: 6px 34px;
                min-width: 143px;
                cursor: pointer;
                color: #231815;
                font-family: "HiraKakuStdN-W8-AlphaNum", sans-serif;
                font-size: 12px;
                font-style: normal;
                letter-spacing: 1.68px;
                line-height: normal;
                text-align: center;
                transition: all 0.3s ease;
            }
        }
    }
}