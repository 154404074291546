.hero {
    padding: 50px 85px 180px 85px;
    background-color: $grey;

    &-title h1 {
        font-size: 45px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &-subtitle p {
        font-size: 22px;
        font-weight: 400;
        letter-spacing: 8px;
        padding-top: 10px;
    }
}

@media screen and (max-width: 1023px) {
    .hero {
        &-title h1 {
            font-size: 50px;
        }
    }
}

@media screen and (max-width: 767px) {
    .hero {
        &-title h1 {
            font-size: 40px;
        }
    }
}

@media screen and (max-width: 428px) {
    .hero {
        padding: 40px 0 90px;
    }
}