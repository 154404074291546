.brand {
    &-page {
        padding: 120px 0 60px;
        background-color: $grey;

        .slider {
            height: auto;
        }

        .services {
            padding-bottom: 120px;
        }

        &__title {
            font-family: helveticaltstd-bold, sans-serif;
            color: #000;
            font-size: 60px;
            font-weight: 700;
            line-height: 1;
        }

        &__subtitle {
            margin: 10px 0 20px 0;
            color: #3c3c3c;
            font-family: hirakakustdn-w8-alphanum, sans-serif;
            font-size: 18px;
            font-style: normal;
            letter-spacing: 5.76px;
        }
    }

    &-main {
        &-wrap {
            padding-bottom: 100px;
            text-align: left;
            margin-top: 120px;
            position: relative;
        }

        &__slide {
            display: flex !important;

            @media screen and (max-width: 1024px) {
                flex-direction: column-reverse;
                justify-content: flex-start;
            }
        }

        &__head {
            display: flex;
            margin-bottom: 50px;
            align-items: center;

            &-inner {
                padding-left: 16px;
                border-left: 2px solid #231815;
            }
        }

        &__logo {
            margin-right: 20px;
            width: 230px;
            /*
            & img {
                max-height: 120px;
            }
            */
        }

        &__heading {
            font-size: 33px;
            line-height: normal;
            font-family: hirakakupro-w6-alphanum, sans-serif;
            font-weight: 700;
            color: #000;
            font-style: normal;
            letter-spacing: 4.59px;
        }

        &__subtitle {
            font-size: 17px;
            line-height: normal;
            font-family: hirakakustdn-w8-alphanum, sans-serif;
            color: #454e4f;
            font-style: normal;
            letter-spacing: 2.38px;
        }

        &__slogan {
            margin-bottom: 24px;
            font-size: 17px;
            line-height: normal;
            font-weight: 700;
            font-family: hirakakustdn-w8-alphanum, sans-serif;
            color: #231815;
            font-style: normal;
            letter-spacing: 2.38px;
        }

        &__desc {
            font-size: 15px;
            line-height: normal;
            font-weight: 500;
            font-family: hirakakustdn-w8-alphanum, sans-serif;
            color: #6e6e6e;
            font-style: normal;
            letter-spacing: normal;

            @media screen and (max-width: 1023px) and (min-width: 420px) {
                font-size: 24px;
                font-style: normal;
                letter-spacing: normal;
            }
        }

        &__img {
            flex-shrink: 0;
            width: 515px;
            height: 483px;
            margin-left: 90px;

            & img {
                object-fit: cover;

                @media screen and (max-width: 1023px) {
                    position: absolute;
                    top: 0; 
                    right: 0; 
                    bottom: 0;
                    left: 0;
                }
            }

            @media screen and (max-width: 1023px) {
                position: relative;
                width: 100%;
                height: 0;
                margin-bottom: 44px;
                margin-left: 0;
                padding-top: 57.1%;
            }
        }
    }
}