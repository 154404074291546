.brand {
    padding: 120px 0 60px;
    text-align: center;

    &-title {
        font-family: helveticaltstd-bold,sans-serif;
        color: #000;
        font-size: 60px;
        font-weight: 700;
        line-height: 1;
    }

    &-subtitle {
        margin: 10px 20px;
        color: #3c3c3c;
        font-family: hirakakustdn-w8-alphanum,sans-serif;
        font-size: 18px;
        letter-spacing: 5.76px;
    }

    &-slider {
        margin-top: 120px;
        padding-bottom: 20px;
        &-card {
            width: 252.5px;
            margin-right: 50px;
            &__img  {
                margin-bottom: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: 120px;
                img {
                    max-width: 90%;
                    max-height: 118px;
                    object-fit: contain;
                    margin: 0 auto;
                }
            }

            &__title {
                margin-bottom: 8px;
                color: $black;
                font-family: hirakakupro-w6-alphanum,sans-serif;
                font-size: 17px;
                letter-spacing: 2.38px;
                line-height: normal;
                font-weight: 700;
            }

            &__description {
                p {
                    color: #6e6e6e;
                    font-family: hirakakupro-w6-alphanum,sans-serif;
                    font-size: 15px;
                    font-style: normal;
                    letter-spacing: 2.1px;
                    line-height: normal;
                    margin-bottom: 28px;
                }
            }

            &__button {
                display: flex;
                justify-content: center;
            }
        }
    }

    &-view-more {
        margin-top: 100px;
        text-align: right;
    }
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
    .brand {
        &-slider {
            &-card {
                &__button {
                    .btn {
                        padding: 15px 45px;
                        min-width: 90%;
                    }
     
                }
            }
        }
    }
}

@media screen and (max-width: 428px) {
    .brand {
        &-slider {
            &-card {
                margin-right: 0;
                &__button {
                    justify-content: center;
                }
            }
        }
    }
}
