.hero {
    &__subtitle {
        color: #000;
        font-family: hirakakustdn-w8-alphanum, sans-serif;
        font-size: 22px;
        font-style: normal;
        letter-spacing: 8px;
        margin-top: 10px;
    }

    &__banner {
        margin-top: 90px;
    }

}

body.featuredNews {
    .news-page {

        &__item {
            @media screen and (max-width: 575px) {
                flex-direction: column;
            }
        }
        &__thumb {
            width: 237px;
            height: 149px;
            margin-right: 25px;

            @media screen and (max-width: 575px) {
                position: relative;
                width: 100%;
                height: 280px;
                margin-bottom: 16px;
            }
        }

        &__date {
            margin-bottom: 8px;
            font-size: 20px;
            font-style: normal;
            letter-spacing: 3.08px;
        }

        &__heading {
            font-size: 20px;
            max-width: 80%;

            @media screen and (max-width: 1023px) {
                max-width: 100%;
            }
        }

        &__desc {
            font-size: 20px;
            max-width: 80%;

            @media screen and (max-width: 1023px) {
                max-width: 100%;
            }
        }

        &__link-wrap {
            @media screen and (max-width: 1023px) {
                display: none;
            }
        }
    }
}