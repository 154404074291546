.footer {
    padding: 130px 0;
    background-color: $grey;

    @media screen and (max-width: 1023px) and (min-width: 768px) {
        padding-top: 250px;
    }    

    @media screen and (max-width: 1023px) {
        padding-bottom: 50px;
    }
 
    &-wrap {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;

        @media screen and (max-width: 1023px) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: start;
        }

        &-info {
            width: 34.6%;
            padding: 40px 30px 60px 0;
            border-right: 1px solid #505050;

            @media screen and (max-width: 1023px) {
                width: 100%;
                border-right: none;
                border-bottom: 1px solid #505050;
                padding: 0 0 100px;
                margin-bottom: 100px;
            }

            &__logo {
                max-width: 266px;
                margin-bottom: 56px;
                display: block;
            }

            &__text {
                margin-bottom: 4px;
                text-transform: uppercase;
                color: #6e6e6e;
                font-family: hirakakupro-w6-alphanum, sans-serif;
                font-size: 15px;
                font-style: normal;
                letter-spacing: 1.5px;
                line-height: normal;

                & a {
                    color: #6e6e6e;
                }

                & a:hover {
                    color: $black;
                }
            }
        }
    }

    &-nav {
        flex-shrink: 0;

        @media screen and (max-width: 1200px) {
            flex-grow: 1;
            margin-left: 100px;
        }

        @media screen and (max-width: 1023px) {
            margin-left: 0;
        }

        &-menu {
            display: flex;
            margin: 0;
            padding: 0;
            margin-bottom: 137px;
            align-items: center;

            @media screen and (max-width: 1200px) {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 30px;
            }

            & li {
                margin-left: 40px;

                @media screen and (max-width: 2400px) {
                    margin-left: 30px;
                }

                @media screen and (max-width: 1400px) {
                    margin-left: 18px;
                }

                @media screen and (max-width: 1200px) {
                    margin-left: 0;
                    margin-bottom: 20px;
                }

                & a {
                    position: relative;
                    text-decoration: none;
                    color: #6d6d6d;
                    font-family: helveticaltstd-bold, sans-serif;
                    font-size: 15px;
                    font-weight: 700;
                    letter-spacing: 1.5px;

                    &:hover:after {
                        width: 100%;
                    }

                    &:after {
                        content: '';
                        position: absolute;
                        display: block;
                        width: 0;
                        -webkit-transition: width .3s ease;
                        -o-transition: width .3s ease;
                        transition: width .3s ease;
                        height: 2px;
                        left: 0;
                        bottom: -5px;
                        background-color: #6e6e6e;
                    }
                }
            }

            & li:first-child {
                margin-left: 0;
            }

            &__item {
                .sub-menu {
                    margin-bottom: 137px;
                }
            }

            &__flag {
                width: 16px;
            }
        }
    }

    &__policy {
        color: #6e6e6e;
        font-family: hirakakupro-w6-alphanum, sans-serif;
        font-size: 15px;
        font-style: normal;
        letter-spacing: 1.5px;
        line-height: normal;
    }
}