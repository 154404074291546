.container {
    max-width: 1330px;
    margin: 0 auto;
    padding: 0 85px;

    @media screen and (max-width: 767px) {
        padding: 0 20px;
    }

    @media screen and (max-width: 428px) {
        max-width: 90%;
        padding: 0;
    }
}

.container-lg {
    max-width: 1450px;
    margin: 0 auto;
    padding: 0 85px;

    @media screen and (max-width: 428px) {
        max-width: 90%;
        padding: 0;
    }
}

.btn {
    color: $black;
    border: 1px solid #000;
    border-radius: 20px;
    padding: 6px 34px;
    min-width: 143px;
    font-size: 12px;
    letter-spacing: 1.68px;
    line-height: normal;
    text-align: center;
    transition: all .3s ease;
    
    &:hover {
        background-color: $black;
    }

    @media screen and (max-width: 1023px) and (min-width: 768px) {
        font-size: 25px;
        font-style: normal;
        letter-spacing: 3.46px;
        line-height: normal;
        padding: 15px 85px;
        min-width: 292px;
        border-radius: 40px;
        border: 2px solid $black;
    }

    @media screen and (max-width: 767px) {
        font-size: 16px;
     }
}

a {
    color: $black;
    text-decoration: none;
    transition: all ease 0.3s;

    &:hover {
        color: $white;
    }
}