@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-ExtraLight.ttf') format("truetype");
    font-weight: 200;
    font-style: normal;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-ExtraLightItalic.ttf') format("truetype");
    font-weight: 200;
    font-style: italic;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-Light.ttf') format("truetype");
    font-weight: 300;
    font-style: normal;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-LightItalic.ttf') format("truetype");
    font-weight: 300;
    font-style: italic;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-Regular.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-Italic.ttf') format("truetype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-Medium.ttf') format("truetype");
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-MediumItalic.ttf') format("truetype");
    font-weight: 500;
    font-style: italic;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-SemiBold.ttf') format("truetype");
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-SemiBoldItalic.ttf') format("truetype");
    font-weight: 600;
    font-style: italic;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-Bold.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-BoldItalic.ttf') format("truetype");
    font-weight: 700;
    font-style: italic;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-ExtraBold.ttf') format("truetype");
    font-weight: 800;
    font-style: normal;
}
@font-face {
    font-family: 'Karla';
    src: url('../fonts/Karla/Karla-ExtraBoldItalic.ttf') format("truetype");
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Libre Baskerville';
    src: url('../fonts/Libre-Baskerville/LibreBaskerville-Regular.ttf') format("truetype");
    font-weight: 400;
    font-style: normal;
}
@font-face {
    font-family: 'Libre Baskerville';
    src: url('../fonts/Libre-Baskerville/LibreBaskerville-Italic.ttf') format("truetype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: 'Libre Baskerville';
    src: url('../fonts/Libre-Baskerville/LibreBaskerville-Bold.ttf') format("truetype");
    font-weight: 700;
    font-style: normal;
}