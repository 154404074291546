.contact {
    padding-top: 200px;

    &-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        &-title-wrap {
            margin-right: 30px;
        }

        @media screen and (max-width: 1023px) {
            flex-direction: column;
        }
    }

    &__title {
        font-family: helveticaltstd-bold, sans-serif;
        color: #000;
        font-size: 60px;
        font-weight: 700;
        line-height: 1;
    }

    &__subtitle {
        margin: 10px 0 20px 0;
        color: #3c3c3c;
        font-family: hirakakustdn-w8-alphanum,sans-serif;
        font-size: 18px;
        font-style: normal;
        letter-spacing: 5.76px; 
    }

    &-phone {
        &-info {
            margin-top: 40px;

            @media screen and (max-width: 1023px) {
                margin-bottom: 80px;
            }
        }

        &__title {
            margin-bottom: 16px;
            color: #6e6e6e;
            font-family: hirakakupro-w6-alphanum, sans-serif;
            font-size: 12px;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
            font-weight: 800;
        }

        &-wrap {
            display: flex;
            align-items: flex-start;

            &__icon {
                margin-right: 6px;
            }
        }

        &-inner {
            &__text {
                color: #6e6e6e;
                font-family: hirakakupro-w6-alphanum, sans-serif;
                font-size: 11px;
                font-style: normal;
                letter-spacing: .44px;
                line-height: normal;
            }

            &__number {
                color: #6e6e6e;
                font-family: hirakakupro-w6-alphanum, sans-serif;
                font-size: 20px;
                font-style: normal;
                letter-spacing: 1.98px;
                line-height: normal;

                &:hover {
                    color: $black;
                }
            }
        }
    }

    &-form-wrap {
        width: 63%;

        @media screen and (max-width: 1023px) {
            width: 100%;
        }
    }

}