* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    position: relative;
    -webkit-font-smoothing: antialiased;
    font-family: $karla;
    overflow-x: hidden;
}

img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

button {
    &:hover {
        cursor: pointer;
    }
}

.material-symbols-outlined {
    font-variation-settings:
        'FILL' 0,
        'wght' 400,
        'GRAD' 0,
        'opsz' 48,
}