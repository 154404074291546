.biz {
    background: #d9d9d9;
    overflow: hidden;
    padding: 170px 0 300px;
    &-title {
        color: #000;
        font-family: helveticaltstd-bold,sans-serif;
        font-size: 60px;
        font-weight: 700;
        line-height: 1;
    }

    &-subtitle {
        margin: 15px 0 20px 0;
        color: #3c3c3c;
        font-family: hirakakustdn-w8-alphanum,sans-serif;
        font-size: 18px;
        font-style: normal;
        letter-spacing: 5.76px;
    }

    &-text {
        color: #231815;
        font-family: hirakakustdn-w8-alphanum,sans-serif;
        font-size: 17px;
        font-style: normal;
        letter-spacing: 5.44px;
        line-height: normal;
    }

    &-slider {
        margin-top: 120px;
    }

    &-slider-card {
        margin-right: 80px;
        &__num {
            position: relative;
            padding-left: 17px;
            margin-bottom: 68px;
            border-left: 2px solid #231815;
            color: #000;
            font-family: hirakakupro-w6-alphanum,sans-serif;
            font-size: 33px;
            font-weight: 700;
            letter-spacing: 4.59px;
            line-height: 48px;
        }

        &__heading {
            margin-bottom: 20px;
            color: #000;
            font-size: 17px;
            font-family: helveticaltstd-bold,sans-serif;
            font-weight: 700;
            font-style: normal;
            letter-spacing: 2.38px;
            line-height: normal;
            text-transform: uppercase;
        }

        &__desc {
            color: #6e6e6e;
            font-family: hirakakustdn-w8-alphanum,sans-serif;
            font-size: 15px;
            font-style: normal;
            letter-spacing: normal;
            line-height: normal;
        }
    }
}

@media screen and (max-width: 768px) {
    .biz {
        padding: 170px 0 150px;

        &-text {
            padding-top: 40px;
        }
    }
}

@media screen and (max-width: 767px) {
    .biz {
        &-title {
            font-size: 44px;
        }
    }
}

@media screen and (max-width: 428px) {
    .biz {
        padding: 80px 0;
        &-slider {
            margin-top: 60px;

            &-card {
                &__num {
                    margin-bottom: 40px;
                }
            }
        }
        &-text {
            padding-top: 40px;
        }
    }
}