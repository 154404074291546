.backToTop {
    display: none;
    position: fixed;
    bottom: 40px;
    right: 40px;
    background-color: $dark-orange;
    border: none;
    color: $white;
    padding: 20px 15px 8px;
    span {
        transform: rotate(90deg);
    }
}