.company {
    padding: 140px 0;

    .container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        @media screen and (max-width: 1023px) {
            flex-direction: column;
        }
    }

    &-title-wrap {
        width: 50%;
        @media screen and (max-width: 1023px) {
            width: 100%;
            margin-bottom: 100px;
        }
    }

    &__title {
        font-family: helveticaltstd-bold,sans-serif;
        color: #000;
        font-size: 60px;
        font-weight: 700;
        line-height: 1;
    }

    &__subtitle {
        margin: 10px 20px 0 0;
        color: #3c3c3c;
        font-family: hirakakustdn-w8-alphanum,sans-serif;
        font-size: 18px;
        font-style: normal;
        letter-spacing: 5.76px;
    }

    &-info-wrap {
        width: 50%;
        @media screen and (max-width: 1023px) {
            width: 100%;
        }
    }

    &-info {
        &-row {
            display: flex;
            align-items: flex-start;
            margin-bottom: 44px;
            @media screen and (max-width: 1023px) and (min-width: 768px) {
                margin-bottom: 80px;
            }       

            @media screen and (max-width: 428px) {
                flex-direction: column;
                text-align: center;
            }
        }

        &__name {
            width: 150px;
            flex-shrink: 0;
            color: #6e6e6e;
            font-family: hirakakustdn-w8-alphanum,sans-serif;
            font-size: 15px;
            font-style: normal;
            letter-spacing: 4.8px;
            line-height: normal;
            @media screen and (max-width: 1023px) {
                width: 170px;
            }

            @media screen and (max-width: 1023px) and (min-width: 768px) {
                font-size: 24px;
                font-style: normal;
                letter-spacing: 2.4px;
                line-height: normal;
            }

            @media screen and (max-width: 428px) {
                width: 100%;
                font-weight: bold;
            }
        }

        &__desc, a {
            color: #6e6e6e;
            font-family: hirakakustdn-w8-alphanum,sans-serif;
            font-size: 15px;
            font-style: normal;
            letter-spacing: 1.5px;
            line-height: normal;
            a:hover {
                color: #000;
                background: none;
            }

            @media screen and (max-width: 428px) {
                width: 100%;
            }
        }
    }
}