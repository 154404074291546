.news {
    background-color: $grey;
    padding: 140px 0 290px;

    &-title {
        font-size: 60px;
        font-weight: 700;
    }

    &-subtitle {
        margin: 10px 0;
        font-size: 18px;
        letter-spacing: 5.76px;
        color: $dark-grey;
    }

    &-list {
        &-item {
            display: flex;
            border-bottom: 1px solid #231815;
            padding: 18px 0;

            &__thumb {
                width: 150px;
                height: 95px;
                margin-right: 12px;
                flex-shrink: 0; 

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }

            &-body {
                -webkit-box-flex: 1;
                flex-grow: 1;
                &__date {
                    margin-bottom: 4px;
                    color: #6e6e6e;
                    font-size: 13px;
                    letter-spacing: 1.82px;
                }

                &__heading {
                    margin-bottom: 16px;
                    color: $black;
                    letter-spacing: 2.24px;
                    line-height: normal;
                    font-weight: 700;

                    & a:hover {
                        color: $black;
                    }
                }

                &__desc {
                    color: #6e6e6e;
                    font-size: 12px;
                    letter-spacing: 1.68px;
                    line-height: normal;
                    max-width: 350px;
                    overflow: hidden;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                }
            }

            &-link {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-negative: 0;
                flex-shrink: 0;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                margin-left: 20px;
            }
        }
        &-item:first-child {
            border-top: 1px solid #231815;
        }
    }

    &-view-more {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        margin-top: 46px;
    }
}

@media screen and (max-width: 768px) {
    .news {
        padding: 140px 0;
    }
}

@media screen and (max-width: 767px) {
    .news {
        &-title {
            font-size: 44px;
        }

        &-list {
            &-item {
                &-link {
                    margin-left: 0;
                }
            }
        }
    }
}

@media screen and (max-width: 428px) {
    .news {
        &-list {
            &-item { 
                flex-direction: column;

                &__thumb {
                    width: 100%;
                    height: 200px;
                }

                &-body {
                    margin: 15px 0;
                }
            }
        }

        &-view-more {
            justify-content: center;
        }
    }
    
}