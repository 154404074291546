.info {
    &-wrap {
        max-width: 1080px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 50px 0;
        margin: 0 auto;
    }

    &-image {
        display: block;
        min-width: 150px;
        height: 150px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
    }

    &-text {
        padding-left: 130px;
        &__description {
            font-size: 14px;
            line-height: 2em;
            text-align: center;

            @media screen and (max-width: 428px) {
                text-align: center;
            }
        }
    }
}

@media screen and (max-width: 428px) {
    .info {
        &-wrap {
            flex-direction: column;
        }

        &-text {
            padding: 0;
        }
    }
}