$white: #FFFFFF;
$black: #231815;
$dark: #282828;
$dark-orange: #AF7F66;
$grey: #f0f0f0;
$light-grey: #d7d6d5;
$dark-grey: #3c3c3c;
$sandy-brown: #F0AD4E;
$vivid-red: #FD0909;
$blue: #5bc0de;

$karla: 'Karla', sans-serif;
$libre: 'Libre Baskerville', sans-serif;

$desktop: 1440px;
$tablet: 992px;
$mobile: 576px;