.slider {
    height: auto;

    &-prev {
        left: 0;
    }
    &-next {
        right: 0;
    }
}

.slick {
    &-arrow {
        position: absolute;
        top: 50%;
        z-index: 1;
        background-color: transparent;
        border: none;
        padding: 0 30px;

        span {
            font-size: 68px;
            color: $white;
        }
    }

    &-dots {
        bottom: 10px;
        li {
            button {
                border: 2px solid $white;
                border-radius: 30px;
                height: 18px;
            }

            button:before {
                font-size: 18px;
            }
        }
    }

    // brand section dots
    .brand &-dots {
        bottom: -40px;
    }

    &-dotted.slick-slider {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 768px) {
    .slider {
        height: auto;
        margin-bottom: 50px;
    }

    .slick {
        &-arrow {
            span {
                font-size: 32px;
            }
        }
    }
}