.hero {
    padding: 40px 0 90px;

    &__title {
        text-transform: uppercase;
        font-size: 45px;
        font-weight: 800;
        color: #000;
        font-family: helveticaltstd-bold, sans-serif;

        @media screen and (max-width: 428px) {
            font-size: 28px;
        }
    }

    &__article {
        padding-top: 40px;

        p {
            margin-bottom: 16px;
            font-family: hirakakustdn-w8-alphanum, sans-serif;
            color: #231815;
            font-size: 16px;
            line-height: 1.4;
        }

        &-info {
            padding-top: 40px;
        }
    }

    &__date {
        margin-bottom: 20px;
        padding-bottom: 16px;
        border-bottom: 1px solid #231815;
        font-family: hirakakustdn-w8-alphanum, sans-serif;
        color: #231815;
        font-size: 22px;
        font-style: normal;
        letter-spacing: 3.08px;
    }

    &__category {
        font-family: helveticaltstd-bold, sans-serif;
        font-size: 20px;
        font-weight: 700;
        color: #231815;
    }
}

.news-page {
    padding: 100px 0 200px;
    background: $grey;

    @media screen and (max-width: 575px) {
        padding: 50px 0 100px;
    }

    &__title {
        font-family: helveticaltstd-bold, sans-serif;
        color: #000;
        font-size: 60px;
        font-weight: 700;
        line-height: 1;

        @media screen and (max-width: 767px) {
            font-size: 44px;
        }
    }

    &__subtitle {
        margin: 10px 0 20px 0;
        color: #3c3c3c;
        font-family: hirakakustdn-w8-alphanum, sans-serif;
        font-size: 18px;
        font-style: normal;
        letter-spacing: 5.76px;
    }

    &__item {
        display: flex;
        padding: 18px 0;
        border-bottom: 1px solid #231815;

        &:first-child {
            border-top: 1px solid #231815;
        }

        @media screen and (max-width: 428px) {
            flex-direction: column;
        }
    }

    &__thumb {
        width: 150px;
        height: 95px;
        margin-right: 12px;
        flex-shrink: 0;

        @media screen and (max-width: 428px) {
            width: 100%;
            height: 180px;
            margin-bottom: 16px;
        }
    }

    &__body {
        flex-grow: 1;
    }

    &__date {
        margin-bottom: 4px;
        font-family: hirakakustdn-w8-alphanum, sans-serif;
        color: #6e6e6e;
        font-size: 13px;
        font-style: normal;
        letter-spacing: 1.82px;

    }

    &__heading {
        margin-bottom: 16px;
        font-family: hirakakupro-w6-alphanum, sans-serif;
        color: #000;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        letter-spacing: 2.24px;
        line-height: normal;

        :hover {
            color: $dark;
        }
    }

    &__desc {
        color: #6e6e6e;
        font-family: hirakakustdn-w8-alphanum, sans-serif;
        font-size: 12px;
        font-style: normal;
        letter-spacing: 1.68px;
        line-height: normal;
        max-width: 350px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &__link {
        &-wrap {
            display: flex;
            flex-shrink: 0;
            align-items: center;
            justify-content: center;
            margin-left: 20px;

            @media screen and (max-width: 428px) {
                margin: 0;
                margin-top: 16px;
            }
        }
    }
}