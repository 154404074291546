.header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: $grey;
    padding: 25px 0;

    &-wrap {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }

    &__logo {
        display: block;
        width: 253px;

        @media screen and (max-width: 768px) {
            width: 50%;
            height: auto;
            margin: 0 auto;
        }
    }

    &-nav {
        &__translation {
            height: 20px;
            position: relative;
            float: right;

            a {
                font-size: 15px;
            }

            .active {
                color: #CC3425;
                font-weight: bold;
            }
            :hover {
                color: #CC3425;
            }
            @media screen and (max-width: 428px) {
                margin-top: 2em;
            }

            button {
                border: none;
                right: 0;
                position: absolute;
            }
        }

        &__list {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @media screen and (max-width: 768px) {
                flex-wrap: wrap;
            }
        }
    }

    &__link {
        display: block;
        font-size: 15px;
        font-weight: 800;
        font-style: normal;
        line-height: 1.7em;
        letter-spacing: 0.1em;
        padding: 13px;
        position: relative;

        &:hover {
            color: $black;
        }

        &:hover:after {
            width: 100%;
        }

        &:after {
            content: "";
            position: absolute;
            left: 0;
            display: block;
            width: 0;
            height: 2px;
            background-color: #6e6e6e;
            -webkit-transition: width .3s ease;
            -o-transition: width .3s ease;
            transition: width .3s ease;
        }

        @media screen and (max-width: 1100px) and (min-width: 1024px) {
            font-size: 13px;
        }

        @media screen and (max-width: 1024px) {
            display: none;
        }
    }
}

.hamburger {
    font-weight: 700;
    font-size: 18px;
    line-height: normal;
    font-family: helveticaltstd-bold, sans-serif;
    color: #262523;
    font-style: normal;
    letter-spacing: 3.5px;
    background-color: transparent;
    border: none;
}

.showMenu {
    display: block;
    transform: translateY(0);
}

:root {
    font-size: 10px;
    --header-height: 9rem;
    --mobile-menu-width: 4rem;
}

#mobile-menu {
    position: absolute;
    top: 50%;
    right: 2rem;
    transform: translateY(-50%);
    z-index: 1;
    width: var(--mobile-menu-width);
    height: var(--mobile-menu-width);
    cursor: pointer;
}

#mobile-menu span {
    position: absolute;
    width: 80%;
    height: 1px;
    left: 10%;
    top: 50%;
    background-color: $black;
    transition: all 0.3s ease-in-out;
}

#mobile-menu span:first-of-type {
    top: 25%;
}

#mobile-menu span:last-of-type {
    top: 75%;
}

#mobile-menu.open span {
    opacity: 0;
}

#mobile-menu.open span:first-of-type,
#mobile-menu.open span:last-of-type {
    transform: rotate(45deg);
    opacity: 1;
    top: 50%;
}

#mobile-menu.open span:last-of-type {
    transform: rotate(-45deg);
}

#mobile-menu-content {
    width: 100%;
    height: calc(100vh - var(--header-height));
    background: $white;
    position: fixed;
    top: -100vh;
    left: 0;
    z-index: 5;
    display: flex;
    align-items: flex-start;
    transition: top 0.7s ease-in-out;
}

#mobile-menu-content.open {
    top: var(--header-height);
}

#mobile-menu-content>div {
    width: 100%;
    height: calc(100vh - var(--header-height));
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

#mobile-menu-content ul {
    width: 100%;
}

#mobile-menu-content ul li {
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
}

#mobile-menu-content ul li a {
    font-size: 18px;
    padding: 1rem;
    text-decoration: none;
    color: $black;
    display: block;
}

@media (max-width: 1024px) {
    header nav ul {
        display: none;
    }
}